.group:hover .zoom {
  transform: scale(1.1);
}

.zoom {
  transition: all 2.5s;
}

.link:hover {
  text-decoration: none !important;
}
